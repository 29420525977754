import React, { useEffect, useState } from "react";
import "./Dashboard.scss";
import VendorMenu from "../component/VendorMenu";
import DashboardTopRight from "../component/DashboardTopRight";
import Graph from "../assets/images/graph.png";
import { AiFillDollarCircle } from "react-icons/ai";
import { FaBus } from "react-icons/fa6";
import { Chart } from "react-google-charts";
import { useDispatch, useSelector } from "react-redux";
import { getVendorDashboardData } from "../redux/action/vendorAction";
import ReactPaginate from "react-paginate";
import Calendar from 'react-awesome-calendar';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { IoCloseSharp } from "react-icons/io5";
import ReservationInfo from "../component/ReservationInfo"

export const data = [
  ["Year", "Annual Income"],
  ["2019", 30000],
  ["2020", 13170],
  ["2021", 46560],
  ["2022", 82030],
  ["2023", 40030],
  ["2024", 28030],
];

export const options = {
  legend: { position: "none" },
};

const currentYear = new Date().getFullYear()
const currentMonth = new Date().getMonth() + 1

const initialState = {
  page: 1,
  year: currentYear,
  month: currentMonth,
};

const Dashboard = () => {
  const [perPage, SetcurrentPage] = useState(10);
  const dispatch = useDispatch();
  const detailData = useSelector((state) => state.vendor);
  const { vendorDashboardData, loader } = detailData
  const [paramState, setParamState] = useState(initialState);
  const { page, year, month } = paramState;

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const [reservationInfoId, setReservationInfoId] = useState("")



  useEffect(() => {
    const getVendorDashboardDatas = async () => {
      try {
        await dispatch(getVendorDashboardData(paramState));
      } catch (error) {
        console.error("Error fetching user profile details:", error);
      }
    };

    getVendorDashboardDatas();
  }, [dispatch, getVendorDashboardData]);

  // const events = vendorDashboardData?.data?.map((data) => {
  //   return data = {
  //     id: data.id,
  //     color: '#363D4D',
  //     from: data.start_date,
  //     to: data.start_date,
  //     title: 'Trip Reserved'
  //   }
  // })

  function getDateObjectsInRange(startDate, endDate) {
    let dates = [];
    let currentDate = new Date(startDate);

    // Ensure the endDate is also a Date object
    endDate = new Date(endDate);

    const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    while (currentDate <= endDate) {
      // Get the day of the week
      let dayOfWeek = dayNames[currentDate.getDay()];

      // Create an object with the date and the day of the week
      let dateObject = {
        date: new Date(currentDate).toISOString().split('T')[0],
        day: dayOfWeek
      };

      dates.push(dateObject);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  }

  function filterDatesByDays(dateObjects, days) {
    // Filter the dates to include only those with days in the provided list
    return dateObjects.filter(dateObject => days.includes(dateObject.day));
  }


  const calendarEvents = []
  const eventsForCalendar = vendorDashboardData?.booking_data?.map((data) => {
    if (data.booking_type === 'recurring') {
      const dateObjectWithDays = getDateObjectsInRange(data.date, data.end_date)
      const daysFromApi = data.weeks
      const startTime = data.start_time
      const endTime = data.end_time

      //when booking is for selected days (recurring)
      if (daysFromApi.length != 0) {
        //const daysToMatchCorrected = daysFromApi.map(day => getNextDay(day))
        const filterDates = filterDatesByDays(dateObjectWithDays, daysFromApi)
        filterDates.forEach(element => {
          data = {
            id: data.id,
            color: '#363D4D',
            from: `${element.date}T${startTime}:00+00:00`,
            to: `${element.date}T${endTime}:00+00:00`,
            title: 'Trip Reserved (Recurring-Weekly)'
          }
          calendarEvents.push(data)
        });
      }
      else {
        // when booking is for everyday (recurring)
        dateObjectWithDays.forEach(element => {
          data = {
            id: data.id,
            color: '#363D4D',
            from: `${element.date}T${startTime}:00+00:00`,
            to: `${element.date}T${endTime}:00+00:00`,
            title: 'Trip Reserved (Recuuring-Daily)'
          }
          calendarEvents.push(data)
        });
      }

    }
    else {
      // whnen booking is for one-time
      data = {
        id: data.id,
        color: '#363D4D',
        from: `${data.date}T${data.start_time}:00+00:00`,
        to: `${data.date}T${data.end_time}:00+00:00`,
        title: 'Trip Reserved (One Time)'
      }
      calendarEvents.push(data)

    }
  })

  const events = calendarEvents


  const handlePageChange = async ({ selected }) => {
    let selectedPage = selected + 1;
    SetcurrentPage(10 * Number(selectedPage));
    let obj = { page: selectedPage, year: year, month: month }
    await dispatch(getVendorDashboardData(obj));
    setParamState({
      page: selectedPage,
      year,
      month
    })
  };

  const handleCalender = (data) => {
    const { year, month } = data
    let obj = { page: page, year: year, month: month + 1 };
    setParamState({
      page,
      year: year,
      month: month + 1
    });
    dispatch(getVendorDashboardData(obj));
  }

  return (
    <>
      <div className="dbCon">
        <div className="dbRight">
          <div>
            <ul className="dbTop p-0 d-flex flex-wrap">
              <li className="col-12 col-sm-4">
                <div className="inner" style={{ background: "#FBF7F3" }}>
                  <div className="hd d-flex align-items-center mb-3">
                    <span>
                      <AiFillDollarCircle className="icon me-2" />
                    </span>{" "}
                    Monthly Payments
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="price col-6">
                      <span className="d-block mb-1">$00.00</span> +45 from
                      last month
                    </div>
                    <div className="graph col-6 text-end">
                      <img src={Graph} alt="" />
                    </div>
                  </div>
                </div>
              </li>
              <li className="col-12 col-sm-4">
                <div className="inner" style={{ background: "#F8F9FB" }}>
                  <div className="hd d-flex align-items-center mb-3">
                    <span>
                      <AiFillDollarCircle className="icon me-2" />
                    </span>{" "}
                    Overdue Payments
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="price col-6">
                      <span className="d-block mb-1">$00.00</span> +72 from
                      last month
                    </div>
                    <div className="graph col-6 text-end">
                      <img src={Graph} alt="" />
                    </div>
                  </div>
                </div>
              </li>
              <li className="col-12 col-sm-4">
                <div className="inner" style={{ background: "#E9FDF0" }}>
                  <div className="hd d-flex align-items-center mb-3">
                    <span>
                      <FaBus className="icon me-2" />
                    </span>{" "}
                    Total Vehicles
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="price">
                      <span className="d-block mb-1">{vendorDashboardData.total_vehicle}</span> In Working
                      Condition
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <div className="row pt-4">
              <div className="col-lg-12 col-12">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="dbSubHd mb-2">Vehicles On Board</div>
                  {/* <div className="mb-2">
                    <select className="smallSelect">
                      <option>This Week</option>
                      <option>This Month</option>
                      <option>This Year</option>
                    </select>
                  </div> */}
                </div>
                <div className="table m-0">
                  <table>
                    <tr>
                      <th>Sr.No.</th>
                      <th>Client Name</th>
                      <th>Vehicle Type</th>
                      <th>Registration No.</th>
                      <th>Make</th>
                      <th>Status</th>
                    </tr>
                    {loader !== false ?
                      <tr className="text-center">
                        <td colSpan="6">
                          <div class="spinner-border" role="status">
                            <span class="sr-only p-0"></span>
                          </div>
                        </td>
                      </tr>
                      :
                      vendorDashboardData?.data?.length > 0 ?
                        vendorDashboardData.data.map((data, index) => (
                          <tr key={index}>
                            <td>{index + 1 + (perPage - 10)}</td>
                            <td>{`${data.school.full_name}`}</td>
                            <td>{data.vehicle.vehicle.vechile_type.type}</td>
                            <td>{data.vehicle.vehicle.license_number}</td>
                            <td>{data.vehicle.vehicle.make.name}</td>
                            <td>
                              <span className={`status ${data.vehicle.vehicle.status}`}>{data.vehicle.duty_status}</span>
                            </td>
                          </tr>
                        )) : <tr className="text-center">
                          <td colSpan="12">
                            <div>
                              <span>No vehicle found</span>
                            </div>
                          </td>
                        </tr>
                    }
                  </table>
                </div>
                <ReactPaginate
                  pageCount={Math.ceil(
                    vendorDashboardData?.pagination_data?.total / 10
                  )}
                  onPageChange={handlePageChange}
                  containerClassName={"paging m-0 p-0 pt-3 d-flex"}
                  activeClassName={"active"}
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                />
              </div>
              <div className='mt-4 outerBox'>
                <div className='dbSubHd mb-3'>Reservations Calendar</div>
                <div className='calenderBox'>
                  {/* <Calendar onChange={handleCalender} events={events} /> */}
                  <Calendar onChange={handleCalender} events={events}
                    onClickEvent={(data) => { setShow3(true); setReservationInfoId(data) }}
                  />
                </div>
              </div>
              {/* <div className="col-lg-4 col-12 mt-lg-0 mt-3">
                <div className="aisBox">
                  <div className="dbSubHd mb-3">Annual Income Statistics</div>
                  <div className="IncomeChart">
                    <Chart
                      chartType="Bar"
                      width="100%"
                      height="480px"
                      data={data}
                      options={options}
                    />
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <Modal className='addbankDetails' show={show3}>
        <Button className='closePopup' onClick={handleClose3}><IoCloseSharp /></Button>
        <ReservationInfo onClose={handleClose3} id={reservationInfoId} />
      </Modal>
    </>
  );
};
export default Dashboard;
