import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import { FaLocationDot } from "react-icons/fa6";
import { FaPhoneVolume } from "react-icons/fa6";
import { driverDetails } from "../redux/action/driverAction";
import { useDispatch, useSelector } from "react-redux";
import { MdEmail } from "react-icons/md";

const DriverInfo = ({ id }) => {
  const dispatch = useDispatch();
  const listsData = useSelector((state) => state.driver);
  const { driverdDetailData } = listsData;

  useEffect(() => {
    const getCountryData = async () => {
      try {
        await dispatch(driverDetails(id));
      } catch (error) {
        console.error("Error fetching user profile details:", error);
      }
    };

    getCountryData();
  }, [dispatch, driverDetails]);

  return (
    <>
      <div className="popupBox p-3 p-md-5">
        <h2 className="text-center fpHd mb-4">Driver Info</h2>
        <div className="pb-4">
          <div className="dbSubHd mb-3">
            {driverdDetailData && driverdDetailData?.country?.name}
          </div>
          <div className="mb-3 d-flex align-items-center">
            <FaLocationDot className="me-3 infoIcon" />{" "}
            {`${driverdDetailData && driverdDetailData?.street_address}  ${
              driverdDetailData && driverdDetailData?.state?.name
            }`}
          </div>
          <div className="mb-3 d-flex align-items-center">
            <MdEmail className="me-3 infoIcon" />{" "}
            {driverdDetailData && driverdDetailData?.email_address}
          </div>
          <div className="d-flex align-items-center">
            <FaPhoneVolume className="me-3 infoIcon" />+
            {driverdDetailData && driverdDetailData?.phone}
          </div>
        </div>
        <div className="driverInfoBot pt-4">
          <ul className="d-flex flex-wrap p-0">
            <li className="col-6 col-sm-4">
              <span className="d-block mb-2">Registration No.</span>
              {driverdDetailData &&
                driverdDetailData.driver_assigned &&
                driverdDetailData.driver_assigned[0] &&
                driverdDetailData.driver_assigned[0].vehicle &&
                driverdDetailData.driver_assigned[0].vehicle.license_number}
            </li>
            <li className="col-6 col-sm-4">
              <span className="d-block mb-2">Shift Time</span>
              {driverdDetailData &&
                driverdDetailData.driver_assigned &&
                driverdDetailData.driver_assigned[0] &&
                driverdDetailData.driver_assigned[0]?.shift_startime}
              -
              {driverdDetailData &&
                driverdDetailData.driver_assigned &&
                driverdDetailData.driver_assigned[0] &&
                driverdDetailData.driver_assigned[0]?.shift_endtime}
            </li>
            <li className="col-6 col-sm-4">
              <span className="d-block mb-2">Shift Type</span>
              {driverdDetailData &&
                driverdDetailData.driver_assigned &&
                driverdDetailData.driver_assigned[0] &&
                driverdDetailData.driver_assigned[0]?.shift_type}
            </li>
            <li className="col-6 col-sm-4">
              <span className="d-block mb-2">Drive Time</span>
              {driverdDetailData &&
                driverdDetailData.driver_assigned &&
                driverdDetailData.driver_assigned[0] &&
                driverdDetailData.driver_assigned[0]?.drive_time}
            </li>
            <li className="col-6 col-sm-4">
              <span className="d-block mb-2">Status</span>
              {driverdDetailData &&
                driverdDetailData.driver_assigned &&
                driverdDetailData.driver_assigned[0] &&
                driverdDetailData.driver_assigned[0]?.duty_status}
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
export default DriverInfo;
