import React, { useState, useEffect } from "react";
import "./VehicleDetail.scss";
import VendorMenu from "../component/VendorMenu";
import DashboardTopRight from "../component/DashboardTopRight";
import { NavLink } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import Slider from "react-slick";
import DriverImg from "../assets/images/driver-img.jpg";
import { useDispatch, useSelector } from "react-redux";
import { vehicleDetails } from "../redux/action/vehicleAction";

const VehicleDetail = () => {
  const [InsuranceDocument, setInsuranceDocument] = useState("");
  const [Permit, setPermit] = useState("");
  const dispatch = useDispatch();
  const detailData = useSelector((state) => state.vehicle);
  const { vehicledDetailData } = detailData;
  let vehicleId = localStorage.getItem("vehicleId");
  
  useEffect(() => {
    dispatch(vehicleDetails(vehicleId));
  }, [vehicleDetails]);

  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <div className="dbCon">
        <div className="dbRight">
          {/* <header className="dbHeader d-flex align-items-center justify-content-between">
            <div className="dbHd">
              <NavLink to="/vehicles" className="me-1">
                <IoMdArrowRoundBack />
              </NavLink>{" "}
              Vehicle Details
            </div>
            <DashboardTopRight />
          </header> */}
          <div>
            <div className="row">
              <div className="DetailSlider col-12 col-lg-6">
                <Slider {...settings}>
                  {vehicledDetailData?.images?.length > 0
                    ? vehicledDetailData?.images?.map((item, i) => (
                        <div>
                          <img
                            src={`${process.env.REACT_APP_SERVER_URL}${item.vehicle_image}`}
                            alt=""
                          />
                        </div>
                      ))
                    : ""}
                </Slider>
              </div>
              <div className="col-12 col-lg-6 mt-3 mt-lg-0">
                <div className="vehicleInfo whtBox d-flex flex-wrap">
                  <div className="map">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.029899067285!2d77.20896507601636!3d28.62886598424625!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd0cd45edc05%3A0x13cfb63e7134e3ac!2sUSA%20visa%20finger%20print%20scan%20center!5e0!3m2!1sen!2sin!4v1705503242309!5m2!1sen!2sin"
                      width="100%"
                      height="100%"
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                  <div className="detail">
                    <ul className="p-0 m-0">
                      <li>
                        Vehicle Type:{" "}
                        <span>{vehicledDetailData?.vechile_type?.type}</span>
                      </li>
                      <li>
                        Color: <span>{vehicledDetailData?.color?.name}</span>
                      </li>
                      <li>
                        Curennt Mileage:{" "}
                        <span>{vehicledDetailData?.curennt_mileage}</span>
                      </li>
                      <li>
                        Registration:{" "}
                        <span>{vehicledDetailData?.license_number}</span>
                      </li>
                      <li>
                        Status: <span>{vehicledDetailData?.status} </span>
                      </li>
                      <li>
                        Year: <span>{vehicledDetailData?.year?.year} </span>
                      </li>
                      <li>
                        seat: <span>{vehicledDetailData?.seat}</span>
                      </li>
                      <li>
                        Vin: <span>{vehicledDetailData?.vin}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="driverInfo whtBox mt-3 p-3">
              <ul className="d-flex flex-wrap m-0 p-0">
                <li className="d-flex align-items-center">
                  <div className="driverImg">
                    <img src={DriverImg} alt="" />
                  </div>
                  <div className="det ps-3">
                    <div className="mb-2 mb-sm-3">
                      Driver: <span>David Baker</span>
                    </div>
                    <div>
                      Phone: <span>+1 987 654 321</span>
                    </div>
                  </div>
                </li>
                <li className="d-flex align-items-center">
                  <div>
                    <div className="mb-2 mb-sm-3">
                      Model: <span>3XJHFUI</span>
                    </div>
                    <div>
                      Make: <span>Blue Bird Corporation</span>
                    </div>
                  </div>
                </li>
                <li className="d-flex align-items-center">
                  <div>
                    <div className="mb-2 mb-sm-3">
                      Vehicle Type: <span>School Bus</span>
                    </div>
                    <div>
                      Run Time: <span>2hr 32min</span>
                    </div>
                  </div>
                </li>
                <li className="d-flex align-items-center">
                  <div>
                    <div className="mb-2 mb-sm-3">
                      VIN: <span>XYZ123456</span>
                    </div>
                    <div>
                      Speed: <span>70km/h</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            {/* <div className="row mt-3">
              <div className="col-12 col-lg-6">
                <div className="whtBox fullHeight p-3">
                  <div className="dbSubHd mb-3">Service Reminder</div>
                  <ul className="serviceRem p-0 m-0">
                    <li className="d-flex justify-content-between mb-2 p-3">
                      <span className="left me-3">155000km</span>
                      <span>01/01/2024</span>
                    </li>
                    <li className="d-flex justify-content-between mb-2 p-3">
                      <span className="left me-3">155000km</span>
                      <span>01/01/2024</span>
                    </li>
                    <li className="d-flex justify-content-between mb-2 p-3">
                      <span className="left me-3">155000km</span>
                      <span>01/01/2024</span>
                    </li>
                  </ul>
                  <div className="pt-3">
                    <Button className="smallBtn">Add</Button>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 mt-3 mt-lg-0">
                <div className="whtBox fullHeight p-3">
                  <div className="dbSubHd mb-3">Services</div>
                  <div className="row align-items-center">
                    <div className="col-6">
                      <img src={ServiceGraph} alt="" />
                    </div>
                    <div className="col-6">
                      <div className="lastCheckin">
                        Last Check In At <span className="pt-2">100000km</span>
                      </div>
                      <div className="mt-3">
                        <Button className="smallBtn">Learn More</Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default VehicleDetail;
