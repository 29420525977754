import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.scss";
import "./assets/css/style.scss";
import ScrollToTop from "./scrollToTop";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Vehicles from "./pages/Vehicles";
import AddVehicle from "./pages/AddVehicle";
import EditVehicle from "./pages/EditVehicle";
import VehicleDetail from "./pages/VehicleDetail";
import Drivers from "./pages/Drivers";
import AddDriver from "./pages/AddDriver";
import EditDriver from "./pages/EditDriver";
import BankDetails from "./pages/BankDetails";
import Transactions from "./pages/Transactions";
import Settings from "./pages/Settings";
import Profile from "./pages/Profile";
import SchoolDashboard from "./pages/SchoolDashboard/SchoolDashboard";
import Vendors from "./pages/SchoolDashboard/Vendors";
import SchoolSettings from "./pages/SchoolDashboard/SchoolSettings";
import SchoolProfile from "./pages/SchoolDashboard/SchoolProfile";
import RegisteredVehicles from "./pages/SchoolDashboard/RegisteredVehicles";
import PaymentHistory from "./pages/SchoolDashboard/PaymentHistory";
import BookVendor from "./pages/SchoolDashboard/BookVendor";
import ResetPassword from "./pages/ResetPassword";
import Mybooking from "./pages/Mybooking";
import Mybookingschool from "./pages/SchoolDashboard/Mybookingschool";
import { ToastContainer } from "react-toastify";
import ProtectedRoute from "./ProtectedRoutes";
import ProtectedRouteSchool from "./ProtectedRoutesSchool";
import Header from "./component/Header";
import SideNavigation from "./component/SideNavigation"
import _404 from "./pages/_404"
function App() {
  return (
    <Router>
      <ToastContainer />
      <ScrollToTop />
      <Header />
      <SideNavigation />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/reset-password" element={<ResetPassword />} />


        {/* -----------Vendors Routes---------------*/}
        <Route
          path="/dashboard"
          element={<ProtectedRoute userType="Vendor"><Dashboard /></ProtectedRoute>}
        />

        <Route
          path="/vehicles"
          element={<ProtectedRoute userType="Vendor"><Vehicles /></ProtectedRoute>}
        />

        <Route
          path="/vehicles/add-vehicles"
          element={<ProtectedRoute userType="Vendor"><AddVehicle /></ProtectedRoute>}
        />

        <Route
          path="/vehicles/edit-vehicles"
          element={<ProtectedRoute userType="Vendor"><EditVehicle /></ProtectedRoute>}
        />

        <Route
          path="/vehicles/vehicles-detail"
          element={<ProtectedRoute userType="Vendor"><VehicleDetail /></ProtectedRoute>}
        /> //skeleton

        <Route
          path="/drivers"
          element={<ProtectedRoute userType="Vendor"><Drivers /></ProtectedRoute>}
        />

        <Route
          path="/drivers/add-driver"
          element={<ProtectedRoute userType="Vendor"><AddDriver /></ProtectedRoute>}
        />

        <Route
          path="/drivers/edit-driver"
          element={<ProtectedRoute userType="Vendor"><EditDriver /></ProtectedRoute>}
        />

        <Route
          path="/bank-details"
          element={<ProtectedRoute userType="Vendor"><BankDetails /></ProtectedRoute>}
        />

        <Route
          path="/transactions"
          element={<ProtectedRoute userType="Vendor"><Transactions /></ProtectedRoute>}
        />

        <Route
          path="/settings"
          element={<ProtectedRoute userType="Vendor"><Settings /></ProtectedRoute>}
        />

        <Route
          path="/profile"
          element={<ProtectedRoute userType="Vendor"><Profile /></ProtectedRoute>}
        />

        <Route
          path="/reservations"
          element={<ProtectedRoute userType="Vendor"><Mybooking /></ProtectedRoute>}
        />


        {/* ----------school routes--------------*/}
        <Route
          path="/school-dashboard"
          element={<ProtectedRouteSchool userType="School"><SchoolDashboard /></ProtectedRouteSchool>}
        />

        <Route
          path="/vendors"
          element={<ProtectedRouteSchool userType="School"><Vendors /></ProtectedRouteSchool>}
        /> //skeleton

        <Route
          path="/vendors/book-vendor"
          element={<ProtectedRouteSchool userType="School"><BookVendor /></ProtectedRouteSchool>}
        />

        <Route
          path="/my-school-reservations"
          element={<ProtectedRouteSchool userType="School"><Mybookingschool /></ProtectedRouteSchool>}
        />

        <Route
          path="/payment-history"
          element={<ProtectedRouteSchool userType="School"><PaymentHistory /></ProtectedRouteSchool>}
        />


        <Route
          path="/school-settings"
          element={<ProtectedRouteSchool userType="School"><SchoolSettings /></ProtectedRouteSchool>}
        />

        <Route
          path="/school-profile"
          element={<ProtectedRouteSchool userType="School"><SchoolProfile /></ProtectedRouteSchool>}
        />

        <Route
          path="/registered-vehicles"
          element={<ProtectedRouteSchool userType="School"><RegisteredVehicles /></ProtectedRouteSchool>}
        />

        <Route path="*" element={<_404 />} />
      </Routes>
    </Router>
  );
}

export default App;
