import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { IoSearchSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { FaCalendarAlt } from "react-icons/fa";
import DatePicker from "react-datepicker";
import DriverAssign from "../component/DriverAssign"
import { vendorBookingVehicleLists } from "../redux/action/vendorAction"
import DocumentPreview from "../component/DocumentPreview";
import { useDebounce } from 'use-debounce';

const initialState = {
  search: "",
  page: 1,
  date: "",
  showingDate: ""
};

const Mybooking = () => {
  const dispatch = useDispatch();
  const [perPage, SetcurrentPage] = useState(10);
  const [iState, updateState] = useState(initialState);
  const listsData = useSelector((state) => state.vehicle);
  const vendorsData = useSelector((state) => state.vendor);
  const { vendorBookingVehicleList, loader } = vendorsData
  const { search, page, date, showingDate } = iState;
  const [value] = useDebounce(search, 300);

  useEffect(() => {
    let obj = { search: search, page: page, date: date };
    dispatch(vendorBookingVehicleLists(obj));
  }, [value])

  const handleSearch = (e) => {
    const { value } = e.target;
    updateState({
      search: value,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let obj = { search: search, page: page, date: date };
        await dispatch(vendorBookingVehicleLists(obj));
      } catch (error) {
        console.error("Error fetching vehicle lists:", error);
      }
    };
    fetchData();
  }, [dispatch]);

  const handlePageChange = async ({ selected }) => {
    let selectedPage = selected + 1;
    SetcurrentPage(10 * Number(selectedPage));
    let obj = { search: search, page: selectedPage, date: date };
    await dispatch(vendorBookingVehicleLists(obj));
    updateState({
      page: selectedPage,
    });
  };

  const handleDateChange = async (date) => {

    const parseData = moment(date, 'DD-MM-YYYY');
    const formattedDate = parseData.format('YYYY-MM-DD');
    if (formattedDate === "Invalid date") {
      let obj = { search: search, page: page, date: "" };
      await dispatch(vendorBookingVehicleLists(obj));
      updateState({
        date: "",
        showingDate: date
      });
      return
    }
    let obj = { search: search, page: page, date: formattedDate };
    await dispatch(vendorBookingVehicleLists(obj));
    updateState({
      date: formattedDate,
      showingDate: date
    });
  };
  return (
    <>
      <div className="dbCon">
        <div className="dbRight">
          <div>
            <div className="d-flex flex-wrap align-items-center justify-content-between">
              <div className="mb-2 total">
                Total Reservations: {vendorBookingVehicleList?.pagination_data?.total}
              </div>
              <div className="mb-2 filterBox">
                <ul className="d-flex flex-wrap align-items-center p-0 vehilcleFilter">

                  <li>
                    <div className="searchBox">
                      <input
                        type="text"
                        className="input"
                        placeholder="Search.."
                        name="search"
                        value={search}
                        onChange={handleSearch}
                      />{" "}
                      <Button className="searchBtn">
                        <IoSearchSharp />
                      </Button>
                    </div>
                  </li>
                  <li className="date">
                    <div className="smallDatepicker position-relative">
                      <DatePicker
                        placeholderText="Date"
                        selected={showingDate}
                        onChange={(date) => handleDateChange(date)}
                      />{" "}
                      <FaCalendarAlt className="icon" />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="table m-0">
              <table>
                <tr>
                  <th>Sr.No.</th>
                  <th>Client Name</th>
                  <th>Driver Name</th>
                  <th>Reservation Sequence</th>
                  <th>Reservation Type</th>
                  <th>Vehicle Type</th>
                  <th>Vehicle License Plate</th>
                  <th>Rate</th>
                  <th>Date</th>
                  <th>Start Time</th>
                  <th>End Time</th>
                  <th>Route Details</th>
                  <th>Action</th>
                </tr>
                {loader !== false ?
                  <tr className="text-center">
                    <td colSpan="12">
                      <div class="spinner-border" role="status">
                        <span class="sr-only p-0"></span>
                      </div>
                    </td>
                  </tr>
                  : vendorBookingVehicleList?.data?.length > 0
                    ? vendorBookingVehicleList?.data?.map((item, i) => (
                      <tr key={i}>
                        <td>{i + 1 + (perPage - 10)}</td>
                        <td className="text-nowrap">{`${item?.school?.company_name}`}</td>
                        <td>{item?.vehicle?.driver?.name}</td>
                        <td>{item.booking_repeat}</td>
                        <td>{item.booking_type}</td>
                        <td className="text-nowrap">
                          {item?.vehicle?.vehicle_type_name}
                        </td>
                        <td>{item?.vehicle?.license_number}</td>
                        <td className="text-nowrap">
                          ${item.total_rate ? item.total_rate : 0}
                        </td>
                        <td>
                          {moment(item.start_date).format("MMMM-DD-YYYY")}
                        </td>
                        <td>{item.shift_startime}</td>
                        <td>{item.shift_endtime}</td>
                        <td>{item.route_detail && <DocumentPreview size="small" preview={`${process.env.REACT_APP_SERVER_URL}${item.route_detail}`} />}</td>
                        <td><DriverAssign bookingId={item.id} schoolId={item.school.id} /></td>

                      </tr>
                    ))
                    : <tr className="text-center">
                      <td colSpan="12">
                        <div>
                          <span>No booking found</span>
                        </div>
                      </td>
                    </tr>}
              </table>
            </div>
            <ReactPaginate
              pageCount={Math.ceil(
                vendorBookingVehicleList?.pagination_data?.total / 10
              )}
              onPageChange={handlePageChange}
              containerClassName={"paging m-0 p-0 pt-3 d-flex"}
              activeClassName={"active"}
              previousLabel={"Previous"}
              nextLabel={"Next"}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default Mybooking;
