import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import VendorMenu from "../component/VendorMenu";
import DashboardTopRight from "../component/DashboardTopRight";
import Button from "react-bootstrap/Button";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { IoSearchSharp } from "react-icons/io5";
import Modal from "react-bootstrap/Modal";
import { IoCloseSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { BiSolidError } from "react-icons/bi";
import ReactPaginate from "react-paginate";
import {
  vehicleLists,
  deleteVehicles,
  vehicleMake,
  vehicleColor,
} from "../redux/action/vehicleAction";
import { useDebounce } from 'use-debounce';

const initialState = {
  make: "",
  color: "",
  search: "",
  page: 1,
};

const Vehicles = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [perPage, SetcurrentPage] = useState(10);
  const dispatch = useDispatch();
  const [id, setId] = useState("");
  const [iState, updateState] = useState(initialState);
  const listsData = useSelector((state) => state.vehicle);
  const { vehicleList, vehicleMakeList, vehicleColorList, loader } = listsData;
  const { make, color, search, page } = iState;
  const [value] = useDebounce(search, 300);

  useEffect(() => {
    let obj = { make: make, color: color, search: search };
    dispatch(vehicleLists(obj));
  }, [value])

  const handleSearch = (e) => {
    const { name, value } = e.target;
    updateState({
      search: value,
    });
  };

  const handleFilterMake = (e) => {
    const { name, value } = e.target;
    let obj = { make: value, color: color, search: search };
    updateState({
      make: value,
    });
    dispatch(vehicleLists(obj));
  };

  const handleFilterColor = (e) => {
    const { name, value } = e.target;
    let obj = { make: make, color: value, search: search };
    updateState({
      color: value,
    });
    dispatch(vehicleLists(obj));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(vehicleMake());
      } catch (error) {
        console.error("Error fetching vehicle lists:", error);
      }
    };
    fetchData();
  }, [vehicleMake]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(vehicleColor());
      } catch (error) {
        console.error("Error fetching vehicle lists:", error);
      }
    };
    fetchData();
  }, [vehicleColor]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let obj = { make: make, color: color, search: search, page: 1 };
        await dispatch(vehicleLists(obj));
      } catch (error) {
        console.error("Error fetching vehicle lists:", error);
      }
    };
    fetchData();
  }, [dispatch]);

  const vehicleDetails = (vehicleId) => {
    localStorage.setItem("vehicleId", vehicleId);
  };

  const editVehicles = (vehicleId) => {
    localStorage.setItem("vehicleId", vehicleId);
  };

  const handleShow = (id) => {
    setShow(true);
    setId(id);
  };

  const handleDelte = async () => {
    try {
      const res = await dispatch(deleteVehicles(id));
      if (res) {
        toast.success("Vehicle Deleted Successfully!", {
          position: toast?.POSITION?.TOP_RIGHT,
        });
        setShow(false);
        dispatch(vehicleLists());
      }
    } catch (err) {
      if (err.response && err.response.status === 400) {
        toast.error(err?.response?.data?.messsage, {
          position: toast?.POSITION?.TOP_RIGHT,
        });
      } else {
        console.log(`error>>>> ${err}`);
      }
    }
  };

  const handlePageChange = async ({ selected }) => {
    let selectedPage = selected + 1;
    SetcurrentPage(10 * Number(selectedPage));
    let obj = { make: make, color: color, search: search, page: selected + 1 };
    await dispatch(vehicleLists(obj));
    updateState({
      page: selectedPage,
    });

    updateState({
      page: selected + 1,
    });
  };
  
  return (
    <>
      <div className="dbCon">
        <div className="dbRight">
          <div>
            <div className="d-flex flex-wrap align-items-center justify-content-between">
              <div className="mb-2 total">
                Total Vehicles: {vehicleList?.pagination_data?.total}
              </div>
              <div className="mb-2 filterBox">
                <ul className="d-flex flex-wrap align-items-center p-0 vehilcleFilter">
                  <li>
                    <div className="searchBox">
                      <input
                        type="text"
                        className="input"
                        placeholder="Search.."
                        name="search"
                        value={search}
                        onChange={handleSearch}
                      />{" "}
                      <Button className="searchBtn">
                        <IoSearchSharp />
                      </Button>
                    </div>
                  </li>
                  <li>
                    <select
                      className="smallSelect"
                      name="make"
                      value={make}
                      onChange={handleFilterMake}
                    >
                      <option value="">Make</option>
                      {vehicleMakeList?.length > 0
                        ? vehicleMakeList?.map((item, i) => (
                          <option value={item.name}>{item.name}</option>
                        ))
                        : ""}
                    </select>
                  </li>
                  <li>
                    <select
                      className="smallSelect"
                      name="color"
                      value={color}
                      onChange={handleFilterColor}
                    >
                      <option value="">Color</option>
                      {vehicleColorList?.length > 0
                        ? vehicleColorList?.map((item, i) => (
                          <option value={item.name}>{item.name}</option>
                        ))
                        : ""}
                    </select>
                  </li>
                </ul>
              </div>
            </div>
            <div className="table m-0">
              <table>
                <tr>
                  <th>Sr.No.</th>
                  <th>Vehicle Type</th>
                  <th>Vehicle License Plate</th>
                  <th>Make</th>
                  <th>Model</th>
                  <th>Year</th>
                  <th>Seat</th>
                  <th>Color</th>
                  <th>Duty Status</th>
                  <th>Action</th>
                </tr>
                {loader !== false ?
                  <tr className="text-center">
                    <td colSpan="12">
                      <div class="spinner-border" role="status">
                        <span class="sr-only p-0"></span>
                      </div>
                    </td>
                  </tr> : vehicleList?.data?.length > 0
                    ? vehicleList?.data?.map((item, i) => (
                      <tr key={i}>
                        <td>{i + 1 + (perPage - 10)}</td>
                        <td>
                          <NavLink
                            to="/vehicles/vehicles-detail"
                            onClick={() => vehicleDetails(item.id)}
                          >
                            {item?.vechile_type?.type}
                          </NavLink>
                        </td>
                        <td className="text-nowrap">{item.license_number}</td>
                        <td>{item?.make?.name}</td>
                        <td className="text-nowrap">{item?.model?.name}</td>
                        <td>{item?.year?.year}</td>
                        <td>{item?.seat}</td>
                        <td>{item?.color?.name}</td>
                        {/* <td>{item?.status}</td> */}
                        <td>
                          <span
                            className={
                              item?.status ===
                                "On Route"
                                ? "status pickupCom"
                                : item?.status ===
                                  "Off Duty"
                                  ? "status offDuty"
                                  : "status unavailable"
                            }
                          >
                            {item?.status === "On Route" ? "Reserved" : item?.status }
                          </span>
                        </td>
                        <td>
                          <div className="actionBtn d-flex">
                            <NavLink
                              to="/vehicles/edit-vehicles"
                              className="me-2"
                              onClick={() => editVehicles(item.id)}
                            >
                              <Button title="Edit" className="rounded-circle">
                                <FiEdit />
                              </Button>
                            </NavLink>
                            <Button
                              title="Delete"
                              className="rounded-circle"
                              onClick={() => handleShow(item.id)}
                            >
                              <RiDeleteBin6Fill />
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))
                    : <tr className="text-center">
                      <td colSpan="12">
                        <div>
                          <span>No vehicle found</span>
                        </div>
                      </td>
                    </tr>}
              </table>
            </div>
            <ReactPaginate
              pageCount={Math.ceil(
                vehicleList?.pagination_data?.total / 10
              )}
              onPageChange={handlePageChange}
              containerClassName={"paging m-0 p-0 pt-3 d-flex"}
              // marginPagesDisplayed={2}
              // pageRangeDisplayed={5}
              activeClassName={"active"}
              previousLabel={"Previous"}
              nextLabel={"Next"}
            />
          </div>
        </div>
      </div>
      <Modal show={show}>
        <Button className="closePopup" onClick={handleClose}>
          <IoCloseSharp />
        </Button>
        <div className="popupBox p-3 p-md-5">
          <div>
            <div className="text-center mb-3 errorIcon">
              <BiSolidError />
            </div>
            <div className="text-center mb-4 deleteHd">
              Are you sure you want to delete?
            </div>
            <div className="text-center">
              <Button className="smallBtn cancel me-3" onClick={handleClose}>
                Cancel
              </Button>
              <Button className="smallBtn auto" onClick={handleDelte}>
                Delete
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default Vehicles;
