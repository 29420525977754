import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "./AddVehicle.scss";
import VendorMenu from "../component/VendorMenu";
import Button from "react-bootstrap/Button";
import DashboardTopRight from "../component/DashboardTopRight";
import DriverAdded from "../component/DriverAdded";
import { IoCloseSharp } from "react-icons/io5";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  getCountrys,
  getStates,
  vehicleInformation,
  addDrivers,
  vehicleDropDown
} from "../redux/action/driverAction";

const initialState = {
  name: "",
  email_address: "",
  street_address: "",
  country: "",
  state: "",
  city: "",
  phone: "",
  zip: "",
  vehicle_id: "",
  shift_type: "",
  shift_startime: "",
  shift_endtime: "",
  // rate: "",
  errors: "",
};

const AddDriver = () => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [iState, updateState] = useState(initialState);
  const [vehicleDetails, setVehicleDetails] = useState("")
  const listsData = useSelector((state) => state.driver);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const { countryList, stateList, vehicleInformationList, vehicleDropdownList } = listsData;

  const {
    name,
    email_address,
    street_address,
    country,
    state,
    city,
    phone,
    zip,
    vehicle_id,
    shift_type,
    shift_startime,
    shift_endtime,
    // rate,
    errors,
  } = iState;

  const handleClose = () => {
    setShow(false);
    navigate("/drivers");
  };

  useEffect(() => {
    const getCountryData = async () => {
      try {
        await dispatch(getCountrys());
      } catch (error) {
        console.error("Error fetching user profile details:", error);
      }
    };

    getCountryData();
  }, [dispatch, getCountrys]);

  useEffect(() => {
    const getInformationData = async () => {
      try {
        await dispatch(vehicleInformation("available"));
      } catch (error) {
        console.error("Error fetching user profile details:", error);
      }
    };

    getInformationData();
  }, [dispatch, vehicleInformation]);

  useEffect(() => {
    const getInformationListData = async () => {
      try {
        await dispatch(vehicleDropDown("available"));
      } catch (error) {
        console.error("Error fetching user profile details:", error);
      }
    };

    getInformationListData();
  }, [dispatch, vehicleDropDown]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateState({
      ...iState,
      [name]: value,
    });
  };

  const handleCountry = (e) => {
    const { name, value } = e.target;
    dispatch(getStates(value));
    updateState({
      ...iState,
      country: value,
    });
  };

  const handleInformtion = (e) => {
    const { name, value } = e.target;
    setVehicleDetails(value)
    updateState({
      ...iState,
      vehicle_id: value,
    });
  };

  let handleValidation = () => {
    let nameEmpty = "";
    let email_addressEmpty = "";
    let street_addressEmpty = "";
    let countryEmpty = "";
    let stateEmpty = "";
    let cityEmpty = "";
    let phoneEmpty = "";
    let zipEmpty = "";
    let vehicle_idEmpty = "";
    let shift_startimeEmpty = "";
    let shift_endtimeEmpty = "";
    let shift_typeEmpty = "";
    // let rateEmpty = "";
    let formIsValid = true;

    if (!name.trim()) {
      nameEmpty = "Please enter name.";
      formIsValid = false;
    }

    if (!email_address.trim()) {
      email_addressEmpty = "Please enter email address.";
      formIsValid = false;
    } else if (!/^.+?@.+?\..+$/.test(email_address)) {
      email_addressEmpty = "Email format is not valid.";
      formIsValid = false;
    }
    if (!street_address.trim()) {
      street_addressEmpty = "Please enter address.";
      formIsValid = false;
    }
    if (!country.trim()) {
      countryEmpty = "Please select country.";
      formIsValid = false;
    }

    if (!state.trim()) {
      stateEmpty = "Please select state.";
      formIsValid = false;
    }
    if (!city.trim()) {
      cityEmpty = "Please enter city.";
      formIsValid = false;
    }
    if (!phone.trim()) {
      phoneEmpty = "Please enter phone number.";
      formIsValid = false;
    }
    if (!zip.trim()) {
      zipEmpty = "Please enter zip code.";
      formIsValid = false;
    }

    // if (!vehicle_id.trim()) {
    //   vehicle_idEmpty = "Please select name.";
    //   formIsValid = false;
    // }
    if (!shift_type.trim()) {
      shift_typeEmpty = "Please select sift type.";
      formIsValid = false;
    }
    if (!shift_startime.trim()) {
      shift_startimeEmpty = "Please select sift start time.";
      formIsValid = false;
    }
    if (!shift_endtime.trim()) {
      shift_endtimeEmpty = "Please select sift end time.";
      formIsValid = false;
    }

    // if (!rate) {
    //   rateEmpty = "Please enter rate.";
    //   formIsValid = false;
    // }

    updateState({
      ...iState,
      errors: {
        nameEmpty,
        email_addressEmpty,
        street_addressEmpty,
        countryEmpty,
        stateEmpty,
        cityEmpty,
        phoneEmpty,
        zipEmpty,
        vehicle_idEmpty,
        shift_startimeEmpty,
        shift_endtimeEmpty,
        shift_typeEmpty,
        // rateEmpty,
      },
    });
    return formIsValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formIsValid = handleValidation();
    if (formIsValid) {
      setIsSubmitLoading(true)
      const data = {
        name,
        email_address,
        street_address,
        country,
        state,
        city,
        phone,
        zip,
        vehicle_id,
        shift_type,
        shift_startime,
        shift_endtime,
        // rate: Number(rate),
      };
      try {
        const res = await dispatch(addDrivers(data));
        if (res) {
          setIsSubmitLoading(false)
          handleShow();
        }
      } catch (err) {
        setIsSubmitLoading(false)
        if (err.response && err.response.status === 400) {
          toast.error(err?.response?.data?.messsage, {
            position: toast?.POSITION?.TOP_RIGHT,
          });
        } else {
          console.log(`error>>>> ${err}`);
        }
      }
    }
  };

  const handleReset = () => {
    updateState({
      name: "",
      email_address: "",
      street_address: "",
      country: "",
      state: "",
      city: "",
      phone: "",
      zip: "",
      vehicle_id: "",
      shift_type: "",
      shift_startime: "",
      shift_endtime: "",
      // rate: "",
    });
  };

  return (
    <>
      <div className="dbCon">
        <div className="dbRight">
          <div className="outerBox">
            <div className="dbSubHd mb-3">Driver Info</div>
            <ul className="form d-flex flex-wrap p-0 pb-5">
              <li className="col-12 col-md-4 col-lg-3">
                <label className="filedHd">Name</label>
                <input
                  type="text"
                  className="formInput"
                  name="name"
                  value={name}
                  onChange={handleChange}
                />
                <span style={{ color: "red" }}>
                  {errors && errors?.nameEmpty}
                </span>
              </li>
              <li className="col-12 col-md-4 col-lg-3">
                <label className="filedHd">Email Address</label>
                <input
                  type="text"
                  className="formInput"
                  name="email_address"
                  value={email_address}
                  onChange={handleChange}
                />
                <span style={{ color: "red" }}>
                  {errors && errors?.email_addressEmpty}
                </span>
              </li>
              <li className="col-12 col-md-4 col-lg-3">
                <label className="filedHd">Phone</label>
                <input
                  type="text"
                  className="formInput"
                  name="phone"
                  value={phone}
                  onChange={handleChange}
                />
                <span style={{ color: "red" }}>
                  {errors && errors?.phoneEmpty}
                </span>
              </li>
              <li className="col-12 col-md-4 col-lg-3">
                <label className="filedHd">Street Address</label>
                <input
                  type="text"
                  className="formInput"
                  name="street_address"
                  value={street_address}
                  onChange={handleChange}
                />
                <span style={{ color: "red" }}>
                  {errors && errors?.street_addressEmpty}
                </span>
              </li>
              <li className="col-12 col-md-4 col-lg-3">
                <label className="filedHd">Country</label>
                <select
                  className="formSelect"
                  name="country"
                  value={country}
                  onChange={handleCountry}
                >
                  <option>Select</option>
                  {countryList?.length > 0
                    ? countryList?.map((item, i) => (
                      <option value={item.id}>{item.name}</option>
                    ))
                    : ""}
                </select>
                <span style={{ color: "red" }}>
                  {errors && errors?.countryEmpty}
                </span>
              </li>
              <li className="col-12 col-md-4 col-lg-3">
                <label className="filedHd">State/Province</label>
                <select
                  className="formSelect"
                  name="state"
                  value={state}
                  onChange={handleChange}
                >
                  <option>Select</option>
                  {stateList?.length > 0
                    ? stateList?.map((item, i) => (
                      <option value={item.id}>{item.name}</option>
                    ))
                    : ""}
                </select>
                <span style={{ color: "red" }}>
                  {errors && errors?.stateEmpty}
                </span>
              </li>
              <li className="col-12 col-md-4 col-lg-3">
                <label className="filedHd">City</label>
                <input
                  type="text"
                  className="formInput"
                  name="city"
                  value={city}
                  onChange={handleChange}
                />
                <span style={{ color: "red" }}>
                  {errors && errors?.cityEmpty}
                </span>
              </li>
              <li className="col-12 col-md-4 col-lg-3">
                <label className="filedHd">ZIP/Postal Code</label>
                <input
                  type="text"
                  className="formInput"
                  name="zip"
                  value={zip}
                  onChange={handleChange}
                />
                <span style={{ color: "red" }}>
                  {errors && errors?.zipEmpty}
                </span>
              </li>

              {/* <li className="col-12 col-md-4 col-lg-3">
                <label className="filedHd">Fare Rate</label>
                <input
                  type="number"
                  className="formInput"
                  name="rate"
                  value={rate}
                  onChange={handleChange}
                />
                <span style={{ color: "red" }}>
                  {errors && errors?.rateEmpty}
                </span>
              </li> */}
            </ul>
            <div className="dbSubHd mb-3">Duty Schedule Info</div>
            <ul className="form d-flex flex-wrap p-0 pb-2">
              <li className="col-12 col-md-4 col-lg-3">
                <label className="filedHd">{"Vehicle (Licence number)"}</label>
                <select
                  className="formSelect"
                  name="vehicle_id"
                  value={vehicle_id}
                  onChange={handleInformtion}
                >
                  <option value="">{vehicleDropdownList?.length === 0 ? "no vehicle avaliable" : "Select"}</option>
                  {vehicleDropdownList?.length > 0
                    ? vehicleDropdownList?.map((item, i) => {

                      return (
                        <option value={item.id}>{item.license_number}</option>
                      )
                    }
                    )
                    : ""}
                </select>
              </li>
              <li className="col-12 col-md-4 col-lg-3">
                <label className="filedHd">Vehicle Type</label>
                {!vehicleDetails &&
                  <input
                    type="text"
                    className="formInput"
                    value="select vehicle first"
                    readOnly
                  />
                }
                {vehicleDropdownList?.length > 0
                  ? vehicleDropdownList?.filter((item) => item.id == vehicleDetails)
                    .map((item, i) => {
                      return (
                        <input
                          type="text"
                          className="formInput"
                          value={item.vehicle_type}
                          readOnly
                          defaultValue="please"
                        // onChange={handleChange}
                        />
                      )
                    }
                    )
                  : (
                    ""
                  )}
              </li>
              <li className="col-12 col-md-4 col-lg-3">
                <label className="filedHd">Shift Type</label>
                <select
                  className="formSelect"
                  name="shift_type"
                  value={shift_type}
                  onChange={handleChange}
                >
                  <option>Select</option>
                  <option value="Monthly">Monthly</option>
                  <option value="Biweekly">Biweekly</option>
                  <option value="Weekly">Weekly</option>
                  <option value="Daily">Daily</option>
                </select>
                <span style={{ color: "red" }}>
                  {errors && errors?.shift_typeEmpty}
                </span>
              </li>
              <li className="col-12 col-md-4 col-lg-3 d-flex">
                <div className="col-6 pe-1">
                  <label className="filedHd">Shift Time (From)</label>
                  <div className="position-relative">
                    <input
                      type="time"
                      className="formInput"
                      name="shift_startime"
                      value={shift_startime}
                      onChange={handleChange}
                    />
                    <span style={{ color: "red" }}>
                      {errors && errors?.shift_startimeEmpty}
                    </span>
                  </div>
                </div>
                <div className="col-6 ps-1">
                  <label className="filedHd">Shift Time (To)</label>
                  <div className="position-relative">
                    <input
                      type="time"
                      className="formInput"
                      name="shift_endtime"
                      value={shift_endtime}
                      onChange={handleChange}
                    />{" "}
                    <span style={{ color: "red" }}>
                      {errors && errors?.shift_endtimeEmpty}
                    </span>
                  </div>
                </div>
              </li>
            </ul>
            <div className="pt-4">
              <Button className="smallBtn cancel me-3" onClick={handleReset}>
                Reset
              </Button>
              <Button className="smallBtn auto" onClick={handleSubmit}>
                {isSubmitLoading ? <div class="spinner-border" role="status">
                  <span class="sr-only p-0"></span>
                </div> : "Submit"}
              </Button>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show}>
        <Button className="closePopup" onClick={handleClose}>
          <IoCloseSharp />
        </Button>
        <DriverAdded onClose={handleClose} />
      </Modal>
    </>
  );
};
export default AddDriver;
