import React, { useState } from "react";
import "./AddVehicle.scss";
import VendorMenu from "../component/VendorMenu";
import Button from "react-bootstrap/Button";
import PasswordUpdate from "../component/PasswordUpdate";
import { IoCloseSharp } from "react-icons/io5";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { changePassword } from "../redux/action/loginAction";
import {useNavigate} from "react-router-dom"
import { logout } from "../redux/action/loginAction";

const initialState = {
  old_password: "",
  new_password: "",
  cpassword: "",
  errors: {},
};

const Settings = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [iState, updateState] = useState(initialState);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const { old_password, new_password, cpassword, errors } = iState;
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;
    updateState({
      ...iState,
      [name]: value,
    });
  };

  let handleValidation = () => {
    let old_passwordError = "";
    let new_passwordError = "";
    let cpasswordError = "";
    let formIsValid = true;

    const passwordRegex =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,20}$/;

    if (!old_password) {
      old_passwordError = "Please enter old password.";
      formIsValid = false;
    }
    if (!new_password) {
      new_passwordError = "Please enter new password.";
      formIsValid = false;
    } else if (!passwordRegex.test(new_password)) {
      new_passwordError =
        "Password must be between 8 and 20 characters and include at least one uppercase character, one lowercase character, one number, and one special character.";
      formIsValid = false;
    }

    if (!cpassword) {
      cpasswordError = "Please enter confirm password.";
      formIsValid = false;
    } else if (new_password !== cpassword) {
      cpasswordError = "New password and confirm password do not match.";
      formIsValid = false;
    }

    updateState({
      ...iState,
      errors: {
        old_passwordError,
        new_passwordError,
        cpasswordError,
      },
    });
    return formIsValid;
  };

  const handleLogout = async () => {
    localStorage.removeItem("token")
    localStorage.removeItem("user_type")
    navigate("/");
    try {
      await dispatch(logout());
    } catch (err) {
      if (err.response && err.response.status === 400) {
        toast.error(err?.response?.data?.messsage, {
          position: toast?.POSITION?.TOP_RIGHT,
        });
      } else {
        console.log(`error>>>> ${err}`);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formIsValid = handleValidation();
    if (formIsValid) {
      setIsSubmitLoading(true)
      const data = { old_password, new_password, cpassword };
      try {
        const res = await dispatch(changePassword(data));
        if (res) {
          setIsSubmitLoading(false)
          toast.success("Password changed, Please login again")
          handleLogout()
          handleShow();
        }
        updateState({
          ...iState,
          emailIdError: "",
          passwordError: "",
        });
      } catch (err) {
          setIsSubmitLoading(false)
          if (err.response && err.response.status === 400) {
          toast.error(err?.response?.data?.messsage, {
            position: toast?.POSITION?.TOP_RIGHT,
          });
        } else {
          console.log(`error>>>> ${err}`);
        }
      }
    }
  };

  return (
    <>
      <div className="dbCon">
        <div className="dbRight">
          <div className="outerBox">
            <div className="dbSubHd mb-3">Change Password</div>
            <div className="d-flex flex-wrap">
              <div className="updatePW">
                <ul className="form p-0">
                  <li>
                    <input
                      type="password"
                      className="formInput"
                      placeholder="Old Password"
                      name="old_password"
                      value={old_password}
                      onChange={handleChange}
                    />
                    <span style={{ color: "red" }}>
                      {errors && errors.old_passwordError}
                    </span>
                  </li>

                  <li>
                    <input
                      type="password"
                      className="formInput"
                      placeholder="New Password"
                      name="new_password"
                      value={new_password}
                      onChange={handleChange}
                    />
                    <span style={{ color: "red" }}>
                      {errors && errors.new_passwordError}
                    </span>
                  </li>
                  <li>
                    <input
                      type="password"
                      className="formInput"
                      placeholder="Confirm Password"
                      name="cpassword"
                      value={cpassword}
                      onChange={handleChange}
                    />
                    <span style={{ color: "red" }}>
                      {errors && errors.cpasswordError}
                    </span>
                  </li>
                </ul>
              </div>
              <div className="PWHint ms-sm-4 ms-0 mt-sm-0 mt-4">
                <span className="mb-2 d-block">Passwords must be/include:</span>
                <ul className="m-0 p-0">
                  <li>Between 8 and 20 characters</li>
                  <li>An uppercase character</li>
                  <li>A lowercase character</li>
                  <li>A number</li>
                  <li>A special character</li>
                </ul>
              </div>
            </div>
            <div className="pt-4">
              <Button className="smallBtn auto" onClick={handleSubmit}>
              {isSubmitLoading ? <div class="spinner-border" role="status">
                  <span class="sr-only p-0"></span>
                </div> : "Submit"}
              </Button>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show}>
        <Button className="closePopup" onClick={handleClose}>
          <IoCloseSharp />
        </Button>
        <PasswordUpdate onClose={handleClose} />
      </Modal>
    </>
  );
};
export default Settings;
