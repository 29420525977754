import { SchoolAction } from "../actionType";
const initialState = {
  loader: false,
  schoolProfileDetailData: {},
  schoolVendorList: [],
  schoolVendorDetail: [],
  bookingDetailDatas: {},
  bookingVehicleList: [],
  schoolDashboardData:{},
  schoolNotificationData:{},
  schoolPaymentHistoryData:{},
  schoolPaymentHistoryStatusData:{},
  bookingInfo:{}
};
export default function school(state = initialState, { type, payload }) {
  switch (type) {
    case SchoolAction.GET_USER_PROFILE_INITIATE: {
      return {
        ...state,
        loader: true,
        schoolProfileDetailData: {},
      };
    }
    case SchoolAction.GET_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        loader: false,
        schoolProfileDetailData: payload,
      };
    }
    case SchoolAction.GET_USER_PROFILE_FAILURE: {
      return {
        ...state,
        loader: false,
        schoolProfileDetailData: {},
      };
    }
    case SchoolAction.VENDOR_LIST_INITIATE: {
      return {
        ...state,
        loader: true,
        schoolVendorList: [],
      };
    }
    case SchoolAction.VENDOR_LIST_SUCCESS: {
      return {
        ...state,
        loader: false,
        schoolVendorList: payload,
      };
    }
    case SchoolAction.VENDOR_LIST_FAILURE: {
      return {
        ...state,
        loader: false,
        schoolVendorList: [],
      };
    }

    case SchoolAction.SCHOOL_VENDOR_DETAIL_INITIATE: {
      return {
        ...state,
        loader: true,
        schoolVendorDetail: [],
      };
    }
    case SchoolAction.SCHOOL_VENDOR_DETAIL_SUCCESS: {
      return {
        ...state,
        loader: false,
        schoolVendorDetail: payload,
      };
    }
    case SchoolAction.SCHOOL_VENDOR_DETAIL_FAILURE: {
      return {
        ...state,
        loader: false,
        schoolVendorDetail: [],
      };
    }

    case SchoolAction.BOOKING_DETAIL_INITIATE: {
      return {
        ...state,
        loader: true,
        bookingDetailDatas: {},
      };
    }
    case SchoolAction.BOOKING_DETAIL_SUCCESS: {
      return {
        ...state,
        loader: false,
        bookingDetailDatas: payload,
      };
    }
    case SchoolAction.BOOKING_DETAIL_FAILURE: {
      return {
        ...state,
        loader: false,
        bookingDetailDatas: {},
      };
    }

    case SchoolAction.BOOKING_VEHICLE_LIST_INITIATE: {
      return {
        ...state,
        loader: true,
        bookingVehicleList: [],
      };
    }
    case SchoolAction.BOOKING_VEHICLE_LIST_SUCCESS: {
      return {
        ...state,
        loader: false,
        bookingVehicleList: payload,
      };
    }
    case SchoolAction.BOOKING_VEHICLE_LIST_FAILURE: {
      return {
        ...state,
        loader: false,
        bookingVehicleList: [],
      };
    }
    case SchoolAction.GET_SCHOOL_DASHBOARD_DATA_INITIATE: {
      return {
        ...state,
        loader: true,
        schoolDashboardData: {},
      };
    }
    case SchoolAction.GET_SCHOOL_DASHBOARD_DATA_SUCCESS: {
      return {
        ...state,
        loader: false,
        schoolDashboardData: payload,
      };
    }
    case SchoolAction.GET_SCHOOL_DASHBOARD_DATA_FAILURE: {
      return {
        ...state,
        loader: false,
        schoolDashboardData: {},
      };
    }

    case SchoolAction.GET_SCHOOL_NOTIFICATION_DATA_INITIATE: {
      return {
        ...state,
        loader: true,
        schoolNotificationData: {},
      };
    }
    case SchoolAction.GET_SCHOOL_NOTIFICATION_DATA_SUCCESS: {
      return {
        ...state,
        loader: false,
        schoolNotificationData: payload,
      };
    }
    case SchoolAction.GET_SCHOOL_NOTIFICATION_DATA_FAILURE: {
      return {
        ...state,
        loader: false,
        schoolNotificationData: {},
      };
    }
    case SchoolAction.GET_SCHOOL_PAYMENT_HISTORY_DATA_INITIATE: {
      return {
        ...state,
        loader: true,
        schoolPaymentHistoryData: {},
      };
    }
    case SchoolAction.GET_SCHOOL_PAYMENT_HISTORY_DATA_SUCCESS: {
      return {
        ...state,
        loader: false,
        schoolPaymentHistoryData: payload,
      };
    }
    case SchoolAction.GET_SCHOOL_PAYMENT_HISTORY_DATA_FAILURE: {
      return {
        ...state,
        loader: false,
        schoolPaymentHistoryData: {},
      };
    }
    case SchoolAction.SET_SCHOOL_PAYMENT_HISTORY_DATA: {
      return {
        ...state,
        loader: false,
        schoolPaymentHistoryStatusData: payload,
      };
    }

    case SchoolAction.GET_BOOKING_INFO_INITIATE: {
      return {
        ...state,
        loader: true,
        bookingInfo: {},
      };
    }
    case SchoolAction.GET_BOOKING_INFO_SUCCESS: {
      return {
        ...state,
        loader: false,
        bookingInfo: payload,
      };
    }
    case SchoolAction.GET_BOOKING_INFO_FAILURE: {
      return {
        ...state,
        loader: false,
        bookingInfo: {},
      };
    }
    
    default:
      return state;
  }
}
