import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "./Login.scss";
import Logo from "../assets/images/logo.png";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ForgotPassword from "../component/ForgotPassword";
import Captcha from "../assets/images/captcha.png";
import { NavLink, useNavigate, Navigate } from "react-router-dom";
import { IoCloseSharp } from "react-icons/io5";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import ReCAPTCHA from "react-google-recaptcha";
import { loginUser } from "../redux/action/loginAction";

const initialState = {
  email: "",
  password: "",
  rememberme: false,
  // isVerified: false,
  errors: {},
};

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [rememberMe, setRememberMe] = useState(false);
  const [iState, updateState] = useState(initialState);
  const { email, password, rememberme, isVerified, errors } = iState;

  const isTokenExist = localStorage.getItem("token")

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      updateState({
        ...iState,
        [name]: value.toLowerCase(),
      });
      return
    }
    updateState({
      ...iState,
      [name]: value,
    });
  };

  const handleOnChange = (value) => {
    if (value) {
      updateState({
        ...iState,
        isVerified: true,
      });
    }
  };

  let handleValidation = () => {
    let emailError = "";
    let passwordError = "";
    let isVerifiedError = "";
    let formIsValid = true;

    if (!email) {
      emailError = "Please enter email ID.";
      formIsValid = false;
    } else if (!/^.+?@.+?\..+$/.test(email)) {
      emailError = "Email format is not valid";
      formIsValid = false;
    }
    if (!password) {
      passwordError = "Please enter password.";
      formIsValid = false;
    }
    // if (!isVerified) {
    //   isVerifiedError = "Please select captcha.";
    //   formIsValid = false;
    // }
    updateState({
      ...iState,
      errors: {
        emailError,
        passwordError,
        // isVerifiedError,
      },
    });
    return formIsValid;
  };

  const handleRememberMeChange = (e) => {
    const { name, checked } = e.target;
    setRememberMe(checked);
  };
  useEffect(() => {
    if (Cookies.get("rememberMe") == "checked") {
      const storedUsername = Cookies.get("email");
      const storedPassword = Cookies.get("password");
      updateState({
        ...iState,
        email: storedUsername || "",
        password: storedPassword || "",
      });
      setRememberMe(true);
    }
  }, [Cookies.get("rememberMe") == "checked"]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formIsValid = handleValidation();
    if (formIsValid) {
      setIsSubmitLoading(true)
      if (rememberMe) {
        Cookies.set("email", email);
        Cookies.set("password", password);
        Cookies.set("rememberMe", "checked");
      } else {
        Cookies.remove("email");
        Cookies.remove("password");
        Cookies.remove("rememberMe");
      }
      const data = { email, password };
      try {
        const res = await dispatch(loginUser(data));
        setIsSubmitLoading(false)
        localStorage.setItem("token", res.token);
        localStorage.setItem("user_type", res.user_type);
        if (res.user_type === "Vendor") {
          toast.success("Vendor Login Successfully", {
            position: toast?.POSITION?.TOP_RIGHT,
          });
          navigate("./dashboard");
        } else if (res.user_type === "School") {
          navigate("./school-dashboard");
          toast.success("School user Login Successfully", {
            position: toast?.POSITION?.TOP_RIGHT,
          });

          updateState({
            ...iState,
            emailIdError: "",
            passwordError: "",
          });
        }
      } catch (err) {
        setIsSubmitLoading(false)
        if (err.response) {
          toast.error(err?.response?.data?.messsage, {
            position: toast?.POSITION?.TOP_RIGHT,
          });
        } else {
          console.log(`error>>>> ${err}`);
        }
      }
    }
  };

  if (isTokenExist) {
    return  <Navigate to="/dashboard" />
  }

  return (
    <>
      <div className="d-flex flex-wrap">
        <div className="loginLeft">
          <div className="loginTop d-flex align-items-center justify-content-center">
            <div className="logininner">
              <div className="loginLogo mb-4">
                <img src={Logo} alt="" />
              </div>
              <h1 className="loginHd mb-2">Sign In</h1>
              <div className="loginText mb-4">
                Enter Your Sign In Details Below
              </div>
              <ul className="loginForm p-0 m-0">
                <li className="mb-3">
                  <input
                    type="text"
                    placeholder="Email Address*"
                    className="formInput"
                    name="email"
                    value={email}
                    onChange={handleChange}
                  />
                  <span style={{ color: "red" }}>
                    {errors && errors.emailError}
                  </span>
                </li>

                <li className="mb-3">
                  <input
                    type="password"
                    placeholder="Password*"
                    className="formInput"
                    name="password"
                    value={password}
                    onChange={handleChange}
                  />
                  <span style={{ color: "red" }}>
                    {errors && errors.passwordError}
                  </span>
                </li>

                <li className="mb-4 d-flex align-items-center justify-content-between">
                  <div className="rem">
                    <label className="d-flex align-items-center">
                      <Form.Check
                        className="checkbox me-2"
                        checked={rememberMe}
                        onChange={handleRememberMeChange}
                      />{" "}
                      Remember me..
                    </label>
                  </div>
                  <div>
                    <a className="fpLink" onClick={handleShow}>
                      Forgot Password?
                    </a>
                  </div>
                </li>
                {/* <li className="mb-4">
                  <ReCAPTCHA
                    sitekey="6Lf0VfEpAAAAAPrm1kfCqgSm3dHg78MZA56iTEqw"
                    onChange={handleOnChange}
                  />
                  <span style={{ color: "red" }}>
                    {errors && errors.isVerifiedError}
                  </span>
                </li> */}
                  {/* <img src={Captcha} alt="" /> */}
                <li>
                  <NavLink to="/dashboard">
                    <Button className="loginBtn text-center " onClick={handleSubmit}>

                      {isSubmitLoading ? <div class="spinner-border" role="status">
                        <span class="sr-only p-0"></span>
                      </div> : "Sign In"}
                    </Button>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
          <div className="loginFooter text-center d-block d-sm-flex flex-wrap align-items-center justify-content-center">
            2024 Aeonian Technology Corporation. All rights reserved.{" "}
            <a href="#" className="ms-2">
              Privacy Policy
            </a>
          </div>
        </div>
        <div className="loginBg"></div>
      </div>
      <Modal show={show}>
        <Button className="closePopup" onClick={handleClose}>
          <IoCloseSharp />
        </Button>
        <ForgotPassword />
      </Modal>
    </>
  );
};
export default Login;
